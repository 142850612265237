<template>
    <div :data-loading="loading" class="calendar">
        <div class="calendar__header">
            <frm-calendar-toolbar />
        </div>
        <div class="calendar__content">
            <frm-calendar-view
                :date="date"
                :events="eventsForFullCalendar"
                :mode="mode"
                :resources="resourcesForFullCalendar"
                :view="modeForFullCalendar"
                @date-click="closeEvent"
                @event-click="openEvent($event)"
                @next="next"
                @previous="previous"
            />
            <frm-calendar-appointment-button />
            <div class="calendar__sidebar">
                <frm-calendar-appointment-info
                    v-if="openedAppointmentEvent"
                    :key="openedAppointmentEvent.id"
                    :anonymous="anonymous"
                    :event="openedAppointmentEvent"
                    :diagnosis-code-enabled="diagnosisCodeEnabled"
                />
                <frm-calendar-note-info v-else-if="openedNoteEvent" :event="openedNoteEvent" />
                <template v-else>
                    <frm-calendar-datepicker :date="date" :selection-mode="selectionMode" data-testid="datepicker-panel" @change="setDate" />
                    <frm-calendar-legend-locations v-model="activeLocations" :locations="locations" data-testid="locations-panel" />
                    <frm-calendar-legend-invoicing-status data-testid="invoicing-status-panel" />
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import CalendarToolbar from './Calendar/Toolbar';
    import CalendarView from './Calendar/View';
    import CalendarLegendLocations from './Calendar/Legend/Locations';
    import CalendarLegendInvoicingStatus from './Calendar/Legend/InvoicingStatus';
    import CalendarDatePicker from './Calendar/Datepicker';
    import CalendarAppointmentButton from './Calendar/AppointmentButton';
    import CalendarAppointmentInfo from './Calendar/AppointmentInfo';
    import CalendarNoteInfo from './Calendar/NoteInfo.vue';
    import { createNamespacedHelpers } from 'vuex';
    import module, { createPersistedState } from '../store/modules/dynamic/calendar';
    import breakpoint from '../services/breakpoint';
    import ModalManager from '../services/modalManager';
    import AppointmentInfoModal from './Modals/AppointmentInfo';

    const NAMESPACE = 'calendar';
    const { mapActions, mapGetters, mapMutations, mapState } = createNamespacedHelpers(NAMESPACE);

    export default {
        components: {
            'frm-calendar-toolbar': CalendarToolbar,
            'frm-calendar-view': CalendarView,
            'frm-calendar-legend-locations': CalendarLegendLocations,
            'frm-calendar-legend-invoicing-status': CalendarLegendInvoicingStatus,
            'frm-calendar-datepicker': CalendarDatePicker,
            'frm-calendar-appointment-button': CalendarAppointmentButton,
            'frm-calendar-appointment-info': CalendarAppointmentInfo,
            'frm-calendar-note-info': CalendarNoteInfo,
        },

        computed: {
            ...mapGetters([
                'date',
                'loading',
                'locations',
                'selectionMode',
                'modeForFullCalendar',
                'eventsForFullCalendar',
                'resourcesForFullCalendar',
                'openedAppointmentEvent',
                'openedNoteEvent',
            ]),
            ...mapState([
                'anonymous',
                'mode',
                'diagnosisCodeEnabled',
            ]),
            activeLocations: {
                get() {
                    return this.$store.state[NAMESPACE].activeLocations;
                },
                set(value) {
                    this.setActiveLocations(value);
                },
            },
        },

        methods: {
            ...mapActions([
                'next',
                'previous',
                'fetchAll',
                'setDate',
                'closeEvent',
            ]),
            ...mapMutations([
                'setActiveLocations',
            ]),

            openEvent(value) {
                this.$store.dispatch('calendar/openEvent', value);

                if (!breakpoint('show-full-calendar')) {
                    ModalManager.openModal(
                        AppointmentInfoModal,
                        {
                            event: this.openedAppointmentEvent,
                            anonymous: this.anonymous,
                            diagnosisCodeEnabled: this.diagnosisCodeEnabled,
                        }
                    ).then(() => {
                        this.closeEvent();
                    });
                }
            },
        },

        created() {
            this.$store.registerModule(NAMESPACE, module);
            createPersistedState(NAMESPACE)(this.$store);

            this.fetchAll();
        },

        beforeDestroy() {
            this.$store.unregisterModule(NAMESPACE);
        },
    };
</script>

<style lang="scss" src="../../sass/calendar.scss" />
