<template>
    <div class="calendar-toolbar">
        <div class="calendar-toolbar__group calendar-toolbar__group--date">
            <div class="button-group">
                <button class="button button--small" @click="previous()">
                    <span class="button__icon-group">
                        <frm-icon class="button__icon" name="caret-left" />
                    </span>
                </button>
            </div>
            <input v-model="date" class="form-input calendar-toolbar__date" type="date">
            <div class="button-group">
                <button class="button button--small" @click="next()">
                    <span class="button__icon-group">
                        <frm-icon class="button__icon" name="caret-right" />
                    </span>
                </button>
            </div>
        </div>

        <div class="calendar-toolbar__group calendar-toolbar__group--filter">
            <div class="calendar-toolbar__filter-mode-container">
                <frm-calendar-filter-mode v-model="filterMode" class="dropdown--block" />
            </div>
            <div class="calendar-toolbar__filter-select-container">
                <frm-calendar-filter-select v-model="filterValue" :mode="filterMode" :multiple="filterMultiple" />
            </div>
        </div>

        <div class="calendar-toolbar__group calendar-toolbar__group--mode">
            <button :class="{'tool-button--active': $store.state.calendar.mode === $options.CalendarMode.DAY}" class="tool-button" type="button" @click="setMode($options.CalendarMode.DAY)">
                <span class="tool-button__icon-group">
                    <frm-icon name="calendar-day" class="tool-button__icon" />
                    <span>{{ $t('calendar.mode-day') }}</span>
                </span>
            </button>
            <button :class="{'tool-button--active': $store.state.calendar.mode === $options.CalendarMode.DAY_PLUS_SEVEN}" class="tool-button" type="button" @click="setMode($options.CalendarMode.DAY_PLUS_SEVEN)">
                <span class="tool-button__icon-group">
                    <frm-icon name="calendar-7-day" class="tool-button__icon" />
                    <span>{{ $t('calendar.mode-7-day') }}</span>
                </span>
            </button>
            <button :class="{'tool-button--active': $store.state.calendar.mode === $options.CalendarMode.WORKWEEK}" class="tool-button tool-button--compact" type="button" @click="setMode($options.CalendarMode.WORKWEEK)">
                <span class="tool-button__icon-group">
                    <frm-icon name="calendar-week" class="tool-button__icon" />
                    <span>{{ $t('calendar.mode-workweek') }}</span>
                </span>
            </button>
            <button :class="{'tool-button--active': $store.state.calendar.mode === $options.CalendarMode.WEEK}" class="tool-button" type="button" @click="setMode($options.CalendarMode.WEEK)">
                <span class="tool-button__icon-group">
                    <frm-icon name="calendar-week" class="tool-button__icon" />
                    <span>{{ $t('calendar.mode-week') }}</span>
                </span>
            </button>
            <button :class="{'tool-button--active': $store.state.calendar.mode === $options.CalendarMode.MONTH}" class="tool-button" type="button" @click="setMode($options.CalendarMode.MONTH)">
                <span class="tool-button__icon-group">
                    <frm-icon name="calendar-month" class="tool-button__icon" />
                    <span>{{ $t('calendar.mode-month') }}</span>
                </span>
            </button>
        </div>

        <div class="calendar-toolbar__group calendar-toolbar__group--anonymous">
            <button :class="{'tool-button--active': $store.state.calendar.anonymous}" class="tool-button" type="button" @click="toggleAnonymous">
                <span class="tool-button__icon-group">
                    <frm-icon name="anonymous" class="tool-button__icon" />
                    <span>{{ $t('calendar.anonymous') }}</span>
                </span>
            </button>
        </div>

        <div class="calendar-toolbar__group calendar-toolbar__group--navigation">
            <button :class="{'tool-button--active': false}" class="tool-button tool-button--compact" type="button" :title="$t('calendar.previous')" @click="previous()">
                <span class="tool-button__icon-group">
                    <frm-icon name="arrow-left" class="tool-button__icon" />
                </span>
            </button>
            <button :class="{'tool-button--active': false}" class="tool-button tool-button--compact" type="button" @click="today()">
                <span class="tool-button__icon-group">
                    <frm-icon name="calendar-day" class="tool-button__icon" />
                    <span>{{ $t('calendar.today') }}</span>
                </span>
            </button>
            <button :class="{'tool-button--active': false}" class="tool-button tool-button--compact" type="button" :title="$t('calendar.next')" @click="next()">
                <span class="tool-button__icon-group">
                    <frm-icon name="arrow-right" class="tool-button__icon" />
                </span>
            </button>
        </div>

        <div class="calendar-toolbar__group calendar-toolbar__group--contact-requests">
            <button class="tool-button" type="button" @click="openContactRequests">
                <span class="tool-button__icon-group">
                    <frm-icon name="list" class="tool-button__icon" />
                    <span>{{ $t('calendar.contact-requests') }}</span>
                </span>
            </button>
        </div>

        <div class="calendar-toolbar__group calendar-toolbar__group--loading">
            <frm-spinner v-if="loading" white />
        </div>

        <div class="calendar-toolbar__group calendar-toolbar__group--search">
            <frm-calendar-search v-model="search" data-testid="calendar-search" @submit="openSearch" />
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import { createNamespacedHelpers } from 'vuex';
    import FilterMode from './FilterMode';
    import FilterSelect from './FilterSelect';
    import Search from './Search';
    import ModalManager from '../../services/modalManager';
    import * as CalendarMode from '../../enums/CalendarMode';
    import * as CalendarFilterMode from '../../enums/CalendarFilterMode';
    import Spinner from '../Spinner';

    const { mapActions, mapGetters } = createNamespacedHelpers('calendar');

    export default {
        components: {
            'frm-calendar-filter-mode': FilterMode,
            'frm-calendar-filter-select': FilterSelect,
            'frm-calendar-search': Search,
            'frm-spinner': Spinner,
        },
        computed: {
            ...mapGetters([
                'loading',
            ]),
            date: {
                get() {
                    return this.$store.getters['calendar/date'].format('YYYY-MM-DD');
                },
                set(value) {
                    this.$store.dispatch('calendar/setDate', moment(value || undefined));
                },
            },
            filterMode: {
                get() {
                    return this.$store.state.calendar.filter.mode;
                },
                set(value) {
                    this.$store.dispatch('calendar/setFilterMode', value);
                },
            },
            filterValue: {
                get() {
                    return this.$store.state.calendar.filter.value;
                },
                set(value) {
                    this.$store.dispatch('calendar/setFilterValue', value);
                },
            },
            filterMultiple() {
                return this.$store.state.calendar.mode === CalendarMode.DAY;
            },
            search: {
                get() {
                    return this.$store.state.calendar.search;
                },
                set(value) {
                    this.$store.commit('calendar/setSearch', value);
                },
            },
        },
        methods: {
            ...mapActions([
                'next',
                'previous',
                'today',
                'setMode',
                'toggleAnonymous',
            ]),
            openSearch() {
                ModalManager.openModal(`/calendar/search?q=${this.search}`)
                    .then(() => {
                        this.search = '';
                    });
            },
            openContactRequests() {
                ModalManager.openModal('/calendar/contact-requests', undefined, undefined, 'full');
            },
        },
        CalendarMode,
        CalendarFilterMode,
    };
</script>
